import React from "react";
import FeaturedCard from "./FeaturedCard";
import TrendingArticles from "./TrendingArticles";

const BlogHero = ({ featuredArticle, trendingArticles }) => {
  return (
    <article className="relative flex flex-col items-center px-4 pt-32  md:pt-40 md:text-left sm:sm:px-12 xl:px-0 overflow-hidden">
      <div className="bg-purple-1100 absolute inset-0 max-h-[463px] md:max-h-[580px] lg:max-h-[740px] -z-10" />
      <section className="w-full lg:max-w-6xl ">
        <h6 className="text-base font-medium uppercase tracking-[1.2px] sm:tracking-widest_1 text-purple-350">
          Encord Blog
        </h6>
        <h1 className="max-w-[288px] sm:max-w-[470px] md:max-w-[unset] text-3.25xl sm:text-[60px] leading-[40px] sm:leading-[68px] font-bold font-manrope tracking-[-1.8px] sm:tracking-[-3px] my-3 text-white">
          Immerse yourself in vision
        </h1>
        <p className=" mb-8 text-sm sm:text-xl font-normal text-gray-2200 lg:mt-0">
          Trends, Tech, and beyond
        </p>
      </section>
      <section className="gap-4 sm:gap-6 flex flex-col lg:flex-row lg:max-w-6xl w-full">
        <FeaturedCard featuredBlog={featuredArticle} />
        <TrendingArticles {...{ trendingArticles }} />
      </section>
    </article>
  );
};

export default BlogHero;
